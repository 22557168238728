
  
  .fourth-container {
    position: relative;
    background-color: #0fafff;
    
  }

  .work-container {
    background: linear-gradient(-45deg, #49c9e6,  #1e8abd, #1E2020);
    background-size: 400% 400%;
    animation: gradient 30s ease infinite;
    width: 100%;
    position: relative;
    
}


  
  .job-desc {
    margin-left: 0;
    padding-left: 1.5em;
    list-style-position: outside;
    color:#fff;
  }

 


  @media screen and (min-width: 601px) {
    .work-history-text {
      color: white;
      font-size: 3vw;
      text-align: center;
      padding-top: 80px;
    }
    .vertical-timeline-element-title {
      font-size: 1.2vw;
      color: #fff;
    }
    .vertical-timeline-element-subtitle {
      font-size: 0.8vw;
      color: #fff;
    }
  }
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    .work-history-text {
      color: white;
      font-size: 7vw;
      text-align: center;
      padding-top: 60px;
    }
    .vertical-timeline-element-title {
      font-size: 4.5vw;
      color: #fff;
    }
    .vertical-timeline-element-subtitle {
      font-size: 3.5vw;
      color: #fff;
    }
  }


