.build-card-title {
  color: rgb(255, 255, 255);
  text-align: center;
  align-items: center;
  font-size: 1.5rem;
}

.card-text {
  color: rgb(255, 255, 255)
}

* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.container {
  position: relative;
  width: 100%;
}

/* Make the image to responsive */
.card {
  display: block;
  width: 100%;
  height: auto;
}

.card-spec-title {
  color: rgb(255, 255, 255);
  line-height: 80%;
  font-weight: bold;
}

.card-spec {
  color: rgb(248, 248, 248);
  line-height: 80%;
  padding-bottom: 10px;
}

/* The overlay effect - lays on top of the container and over the image */
.card {
  left: 10%;
  width: 80%;
  opacity:1;
  color: white;
  font-size: 1rem;
  margin:0;
}

.overlay2 {
    position: absolute;
    bottom: 2%;
    right: 27.5%;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 45%;
    transition: .5s ease;
    opacity:1;
    color: white;
    font-size: 1rem;
    padding: 5px;
    text-align: center;
  
  }

  .overlay3 {
    position: absolute;
    bottom: 2%;
    right: 20%;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 60%;
    transition: .5s ease;
    opacity:1;
    color: white;
    font-size: 1rem;
    padding: 5px;
    text-align: center;
  
  }
