

.main-title {
    text-align: center;
    font-size: 4rem;
    color:#fff;
}

.subtitle {
    text-align: center;
    font-size: 1.2rem;
    color:#fff;
}

body {
    background: #eb4924;
  }


/* Demo Purpose Only*/
.demo {
  font-family: 'Raleway', sans-serif;
    color:#fff;
    display: block;
    margin: 0 auto;
    padding: 15px 0;
    text-align: center;
}
.demo a{
  font-family: 'Raleway', sans-serif;
color: #2ecc71;		
}


.ant-card-meta-title {
  font-size: 3rem;
  text-align: center;
}


.ant-card-meta-description {
  font-size: 1.5rem;
  text-align: center;
}

.projects-outer {
  background: linear-gradient(-45deg, #49c9e6,  #1e8abd, #1E2020);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
  width: 100%;
  height:100vh;
  position: relative;
  
}


.projects-inner {
  height: 80vh;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  
}


.project-title {
  color:#fff;
  font-size: 2.3rem;
}
.project-url {
  color:#fff;
  font-size: 1.5rem;
}

.project-description {
  color:#fff;
  font-size: 1.3rem;
}

.project-tools-title {
  color:#fff;
  font-size: 1.1rem;
}

.full-page {
  height: 80vh;
}

.project-tools {
  color:#fff;
  font-size: 1.1rem;
}

.project-date {
  color:#fff;
  font-size: 1.4rem;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}