

.main-title {
    text-align: center;
    font-size: 3.3rem;
    color:#fff;
}

.subtitle {
  text-align: center;
  font-size: 1.2rem;
  color:#fff;
}

.card-title {
  color:#fff;
  text-align: left;
  font-size: 2.7rem;
}

.card-holder {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.card-bolded {
  color:#fff;
  text-align: left;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 70%;
}

.card-normal {
  color:rgb(212, 216, 218);
  text-align: left;
  font-size: 1.1rem;
  line-height: 105%;
}


body {
    background: #eb4924;
  }
.downArrow{
    position: relative;
    bottom: 45%;
    left: 50%;
}
.bounce {
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
/* Demo Purpose Only*/
.demo {
  font-family: 'Raleway', sans-serif;
    color:#fff;
    display: block;
    margin: 0 auto;
    padding: 15px 0;
    text-align: center;
}
.demo a{
  font-family: 'Raleway', sans-serif;
color: #2ecc71;		
}


body { background: #2d2d37; color:#fff;}
a { color: white; text-decoration: none; }

.arrow {
  text-align: center;
  margin: 8% 0;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}  

.ant-card-meta-title {
  font-size: 3rem;
  text-align: center;
}


.ant-card-meta-description {
  font-size: 1.5rem;
  text-align: center;
}

.battlestations-main {
    background: linear-gradient(-45deg, #49c9e6,  #1e8abd, #1E2020);
    background-size: 400% 400%;
    animation: gradient 30s ease infinite;
    width: 100%;
    height:100vh;
    position: relative;
    
}

.holder {
    width: 60vw;
    height:50vh; 
}

.battlestations {
    height: 80vh;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    
}

.project-title {
  color:#fff;
  font-size: 2.3rem;
}
.project-url {
  color:#fff;
  font-size: 1.5rem;
}

.project-description {
  color:#fff;
  font-size: 1.3rem;
}

.project-tools-title {
  color:#fff;
  font-size: 1.1rem;
}

.full-page {
  height: 80vh;
}

.project-tools {
  color:#fff;
  font-size: 1.1rem;
}

.project-date {
  color:#fff;
  font-size: 1.4rem;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

