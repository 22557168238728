
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  
  .secondary-container {
    height: 100vh;
    position: relative;
    background-color: #1E333C;
    text-align: center;
  }
  
  .third-container {
    height: 100vh;
    position: relative;
    background-color: #7C9CAC;
    text-align: center;
  }
  
  .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    
  }
  
  
  
  
  .site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
  }
  
  .icon-text {
    color: rgb(241, 232, 232);
    font-size: 1vw;
  }
  
  @media screen and (min-width: 601px) {
    .main-text {
      color: white;
      font-size: 3.5vw;
    }
    .secondary-text {
      color: white;
      font-size: 1.6vw;
    }
    .title-text {
      color: rgb(255, 255, 255);
      font-size: 3vw;
    }
    
    .description-text {
      color: rgb(236, 236, 236);
      font-size: 1.3vw;
    }
    .icon-text {
      color: rgb(246, 246, 246);
      font-size: 1.2vw;
    }
  
    .skills-badge {
      height: 100%;
      width: 100%;
    }
  
    .skill {
      color: rgb(246, 246, 246);
      font-size: 0.9vw;
      line-height: 0.9vw;
  
    }
  }
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    .main-text {
      color: white;
      font-size: 7vw;
    }
    .secondary-text {
      color: white;
      font-size: 4vw;
    }
    .title-text {
      color: rgb(255, 249, 249);
      font-size: 7vw;
    }
    
    .description-text {
      color: rgb(250, 243, 243);
      font-size: 3vw;
    }
    .icon-text {
      color: rgb(255, 255, 255);
      font-size: 3.2vw;
    }
  
    .skills-badge {
      height: 100%;
      width: 100%;
    }

    .skill {
      color: rgb(246, 246, 246);
      font-size: 2.5vw;
      line-height: 3vw;
  
    }
  }